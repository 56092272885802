/**
 * Brand Constants
 * Add your brand details here.
 */
export const siteConfig = {
  siteTitle: 'immotree - linktree for real estate agents',
  siteDescription:
    'Helping agents build micro-sites to acquire buyer/seller leads',
  siteUrl: 'https://immotr.ee',
  siteImage: '/images/og-image.png',
  favicon: '/favicon.ico',
  twitterHandle: '@immotree',
  email: 'hello@immotr.ee',
  address: 'Berlin, Germany',
}
